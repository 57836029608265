import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import va from "./modules/va-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    va
  }
})

