<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card
          color="blue"
          class="px-5 py-3"
          icon="mdi-cube-scan"
          title="Tokens"
          value="184"
          sub-icon="mdi-cube-scan"
          sub-icon-color="red"
          sub-text="Get More Space..."
        >
          <v-row>
            <v-col cols="12" md="3" v-if="permisosUsuarios.user_htec">
              <v-combobox
                v-model="filter.idCliente"
                :items="itemsClientesFilter"
                label="Cliente"
                chips
                item-value="id"
                item-text="descripcion"
                return-object
                dense
                hide-details="auto"
                @change="changeCliente"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="5">
              <v-combobox
                v-model="filter.idEmpresa"
                :items="itemsEmpresasFilter"
                label="Empresa"
                chips
                item-value="id"
                item-text="descripcion"
                return-object
                dense
                hide-details="auto"
                multiple
                @change="changeEmpresas"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="search" label="Buscar"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="light-blue darken-4"
                    fab
                    small
                    @click.prevent="openDialogCrearLicencia"
                    :loading="loadingLicencia"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Crear Licencia</span>
              </v-tooltip>
              &nbsp;&nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="light-green darken-4"
                    fab
                    small
                    click.prevent="openDialogCrearLicencia"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>Exportar Excel</span>
              </v-tooltip>
              &nbsp;&nbsp;
              <v-progress-circular
                :width="3"
                color="red"
                indeterminate
                v-if="loadingOption"
              ></v-progress-circular>
            </v-col>-->
          </v-row>
          <v-card-text>
            <v-data-table
              dense
              v-model="selectMedicamentos"
              :headers="headers"
              :items="items"
              :search="search"
              :show-select="false"
              :hide-default-header="true"
              :items-per-page="100"
              item-key="idproducto"
              class="mt-3"
              :single-select="false"
              :loading="loading"
            >
              <template v-if="search" v-slot:no-results>
                NO RESULTS HERE!
              </template>

              <template v-if="items.length == 0" v-slot:no-data>
                No hay registros
              </template>
              <template v-slot:header="{ props, on }">
                <thead>
                  <tr>
                    <th
                      v-for="header in props.headers"
                      :key="header.value"
                      :style="header.width"
                    >
                      <v-simple-checkbox
                        v-if="
                          header.value === 'data-table-select' &&
                          !props.singleSelect
                        "
                        v-model="props.everyItem"
                        :indeterminate="props.someItems && !props.everyItem"
                        color="purple"
                        @input="on['toggle-select-all']"
                      ></v-simple-checkbox>
                      <span style="
                          color: #000 !important;
                          font-weight: bold;
                          font-size: 12px;
                        " v-else>{{ header.text }}</span>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item, isSelected }">
                <tr
                  :class="isSelected ? 'blue lighten-4' : ''"
                >
                  <td style="width: 4% !important">{{ item.empresa }}</td>
                  <td style="width: 5% !important">{{ item.fecha }}</td>
                  <td style="width: 10% !important">{{ item.idtoken }}</td>
                  <td style="width: 2% !important">
                    {{ item.plataforma }} <br />
                    <span style="color: #01579b; font-weight: bold">{{
                      item.idproducto
                    }}</span>
                  </td>
                  <td style="width: 4% !important">{{ item.idsubproducto }}</td>
                  <td style="width: 15% !important">
                    <v-badge
                      color="green"
                      bordered
                      inline
                      left
                      :content="item.cant_permitida"
                    >
                      <span style="font-size: 10px">Permitidas</span>
                    </v-badge>
                    <br />
                    <v-badge
                      color="pink"
                      bordered
                      inline
                      right
                      :content="item.cant_usada.toString()"
                    >
                      <span style="font-size: 10px">Usadas</span>
                    </v-badge>
                  </td>
                  <td style="width: 4% !important">
                    <v-btn
                      dark
                      class="light-blue darken-4"
                      fab
                      x-small
                      @click.prevent="generarQr(item)"
                    >
                      <v-icon>mdi-qrcode</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogQr" scrollable max-width="550px">
      <v-card>
        <v-card-title><strong>{{textDialogQr}}</strong></v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12" class='d-flex justify-center'>
              <qrcode-vue v-if='dialogQr' :value="tokenQr" :size="500" level="H" />
              </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialogQr">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: "DashboardPagesTimeline",
  components: {
    QrcodeVue,
  },
  data: () => ({
    dialogQr: false,
    textDialogQr: "",
    tokenQr: "",
    menu: false,

    headers: [
      { text: "Empresa", value: "empresa", width: "width: 160px !important;" },

      { text: "Fecha", value: "fecha", width: "width: 50px !important;" },
      {
        text: "Token",
        value: "idtoken",
        width: "width: 350px !important;",
      },
      {
        text: "Plataforma/Producto",
        value: "plataforma",
        width: "width: 50px !important;",
      },
      {
        text: "Sub Producto",
        value: "idsubproducto",
        width: "width: 50px !important;",
      },
      {
        text: "Cant. Permitida/Cant. Usada",
        value: "cant_usada",
        width: "width: 50px !important;",
      },
      {
        text: "Acciones",
        value: "action",
        width: "width: 50px !important;",
      },
    ],

    addProductModal: false,
    clickRow: false,
    search: "",
    items: [],
    itemsStockByAlmacen: [],
    selectMedicamentos: [],
    xopenPrecios: false,
    menuFiltro: false,
    x: 0,
    y: 0,
    loadingStock: false,

    verStockModal: false,
    titleVerStock: "",
    idProducto: "",

    itemProducto: {},
    verProductoModal: false,
    titleVerProducto: "",

    anularModal: false,
    titleAnular: "",
    bodyAnular: "",

    esLote: false,
    dialogFiltro: false,


    itemsEmpresas: [],
    itemsEmpresasFilter: [],
    itemsClientesFilter: [],
    filter: {
      idEmpresa: "",
      idCliente: "",
    },
    permisosUsuarios: {},
    loadingLicencia: false,
    loadingOption: false,
    loading: false,
  }),
  async created() {
    await this.listarPermisos();
    //console.log(this.permisosUsuarios.user_htec, 'permisos')
    if(this.permisosUsuarios.user_htec){
      await this.listarClientes();
      this.filter.idCliente = this.itemsClientesFilter[0];
    }


    await this.listarEmpresas();
    let idEmpresas = "";
    /*this.itemsEmpresasFilter.map((e) => {
      idEmpresas += e.id + ",";
    });*/
    this.filter.idEmpresa = this.itemsEmpresasFilter;
    this.filter.idEmpresa.map((e) => {
      idEmpresas += e.id + ",";
    });
    idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);
    await this.listarTokens(idEmpresas);
  },
  methods: {
    async generarQr (item) {
      try {
        this.tokenQr = "";
        const response = (
          await this.$xxhttp.request(
            `qr-token?idtoken=${item.idtoken}`
          )
        ).data;

        if (!response.status) return;

        this.textDialogQr = `Licencia - ${item.plataforma}/${item.idproducto} - [${item.idtoken}]`
        this.tokenQr = response.data
        this.dialogQr = true;


      } catch (e) {
        console.log(e);
      }
    },
    async closeDialogQr(){
      this.dialogQr = false;
      this.textDialogQr = "";
      this.tokenQr = "";
    },
    async listarPermisos() {
      try {
        const response = (
          await this.$xxhttp.request(
            `permisos`
          )
        ).data;
        this.permisosUsuarios = response.status ? response.data : {};
      } catch (e) {
        console.log(e);
      }
    },
    async listarClientes() {
      try {
        const response = (
          await this.$xxhttp.request(
            `licencias/clientes`
          )
        ).data;
        this.itemsClientesFilter = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
    async listarEmpresas() {
      try {
        let idCliente = '';
        let url = 'licencias/empresas';
        if(this.permisosUsuarios.user_htec){
          idCliente = this.filter.idCliente.id;
          url = `${url}?idclientex=${idCliente}`;
        }

        const response = (
          await this.$xxhttp.request(url)
        ).data;
        this.itemsEmpresas = response.status ? response.data : [];
        this.itemsEmpresasFilter = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
    async changeEmpresas(evt) {
      let idEmpresas = "";
      this.filter.idEmpresa.map((e) => {
        idEmpresas += e.id + ",";
      });
      idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);
      await this.listarTokens(idEmpresas);
    },
    async changeCliente(){
      try {
        const idCliente = this.filter.idCliente.id;

        this.loading = true;
        await this.listarEmpresas();

        let idEmpresas = "";
        this.filter.idEmpresa = this.itemsEmpresasFilter;
        this.filter.idEmpresa.map((e) => {
          idEmpresas += e.id + ",";
        });
        idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);

        await this.listarTokens(idEmpresas);
        this.loading = false;
      } catch (e) {
        console.log(e)
      }
    },
    async listarTokens(filterEmpresas = '') {
      try {
        const idCliente = this.filter.idCliente.id;
        this.loading = true;
        const idEmpresas = filterEmpresas;
        console.log(idCliente, 'idcliente', idEmpresas)
        this.loading = true;
        const response = (
          await this.$xxhttp.request(
            `tokens?idclientex=${idCliente}&idempresas=${idEmpresas}`
          )
        ).data;
        this.items = response.status ? response.data : [];
        console.log("-->",this.items)
        this.loading = false;
        console.log(this.items)
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

  },
};
</script>
