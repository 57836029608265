var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.emailsSubscriptionChart.data,"options":_vm.emailsSubscriptionChart.options,"responsive-options":_vm.emailsSubscriptionChart.responsiveOptions,"color":"#E91E63","hover-reveal":"","type":"Bar"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Change Date")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v("updated 10 minutes ago")])]},proxy:true}])},[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Website Views ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Last Campaign Performance ")])])],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.dailySalesChart.data,"options":_vm.dailySalesChart.options,"color":"success","hover-reveal":"","type":"Line"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Change Date")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v("updated 4 minutes ago")])]},proxy:true}])},[_c('h4',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Daily Sales ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-arrow-up ")]),_c('span',{staticClass:"green--text"},[_vm._v("55%")]),_vm._v("  increase in today's sales ")],1)])],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('base-material-chart-card',{attrs:{"data":_vm.dataCompletedTasksChart.data,"options":_vm.dataCompletedTasksChart.options,"hover-reveal":"","color":"info","type":"Line"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"light":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Change Date")])])]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v("campaign sent 26 minutes ago")])]},proxy:true}])},[_c('h3',{staticClass:"card-title font-weight-light mt-2 ml-2"},[_vm._v(" Completed Tasks ")]),_c('p',{staticClass:"d-inline-flex font-weight-light ml-2 mt-1"},[_vm._v(" Last Last Campaign Performance ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"info","icon":"mdi-twitter","title":"Followers","value":"+245","sub-icon":"mdi-clock","sub-text":"Just Updated"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"primary","icon":"mdi-poll","title":"Website Visits","value":"75.521","sub-icon":"mdi-tag","sub-text":"Tracked from Google Analytics"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"success","icon":"mdi-store","title":"Revenue","value":"$ 34,245","sub-icon":"mdi-calendar","sub-text":"Last 24 Hours"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"orange","icon":"mdi-sofa","title":"Bookings","value":"184","sub-icon":"mdi-alert","sub-icon-color":"red","sub-text":"Get More Space..."}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }