import Vue from 'vue'
import Router from 'vue-router'

// layout dashboard
import DashboardLayout from '@/views/dashboard/Index'
import Dashboard from '@/views/dashboard/Dashboard'

// pages
import Licencias from '@/views/dashboard/licencias/Licencias'
import Token from '@/views/dashboard/licencias/Token'
import SolicitudAcceso from '@/views/dashboard/licencias/SolicitudAcceso'
import Preferencias from '@/views/dashboard/licencias/Preferencias'


// layout login
import LoginLayout from '@/views/IndexLogin'
import Login from '@/views/Login'

// middleware
import auth from "@/middleware/auth";
import notauth from '@/middleware/notauth'

Vue.use(Router)

const login = {
  path: "/",
  component: LoginLayout,
  name: 'LoginLayout',
  children: [
    {
      path: 'x-login',
      name: 'Login',
      component: Login,
      meta: {middleware: notauth}
    }
  ]
}



const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [        
      // Dashboard
      {
        name: 'Dashboard',
        path: '/',
        component: Dashboard,
        meta: { middleware: auth }
      },
      {
        name: 'Licencias',
        path: 'licencia/list',
        component: Licencias,
        meta: { middleware: auth }
      },
      {
        name: 'Tokens',
        path: 'tokens/list',
        component: Token,
        meta: { middleware: auth }
      },
      {
        name: 'SolicitudAcceso',
        path: 'solicitud-accesos/list',
        component: SolicitudAcceso,
        meta: { middleware: auth }
      },
      {
        name: 'Preferencias',
        path: 'preferencias/list',
        component: Preferencias,
        meta: { middleware: auth }
      },
     
      
    ],
  },
  login,
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})


function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({...context, next: nextMiddleware});
  };
}


router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = {from, next, to, router};
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  return next();
});


export default router

/*
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      name: "Home",
      children: [        
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
    {
      path: '/x-login',
      component: () => import('@/views/IndexLogin'),
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/Login'),
        },
      ]
    }
  ],
})
*/
