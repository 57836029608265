import Vue from "vue";
import router from "@/router/router";

import VueAxios from "vue-axios";
import {VueAuthenticate} from "vue-authenticate";

import axios from "axios";
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  //registerUrl: "/register"
});

export default {
  state: {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    

    // local interno
    user: localStorage.getItem("user"),
    isToken: localStorage.getItem("vue-authenticate.vueauth_access_token2") !== null,
    token: localStorage.getItem("vue-authenticate.vueauth_access_token2") ? localStorage.getItem("vue-authenticate.vueauth_access_token2") : 'holi',
    
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAuthenticated2(state) {
      return state.isToken;
    },
    getUser(state) {
      return JSON.parse(state.user);
    },
    getToken(state) {
      return state.token
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    isAuthenticated2(state, payload) {
      state.token = payload.token;
      state.isToken = true
      state.user = payload.user;
      localStorage.setItem('vue-authenticate.vueauth_access_token2', state.token)
      localStorage.setItem('user', state.user)
    },
    logout2(state, payload) {
      state.token = null;
      state.user = null;
      state.isToken = false;
      localStorage.removeItem('vue-authenticate.vueauth_access_token2');
      localStorage.removeItem('user');
    }
  },

  actions: {
    
    loginsave(context, payload) {
      //console.log(payload)
      context.commit("isAuthenticated2", {
        token: payload.access_token,        
        user: JSON.stringify(payload.user)
      });
      
      //console.log(router)
      router.push({ name: "Home" });
    },

  
    logout2(context, payload) {
      context.commit("logout2");      
      router.push({name: "Login"});
    }
  }
};