<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card
          color="blue"
          class="px-5 py-3"
          icon="mdi-license"
          :title="'Licencias (' + items.length + ')'"
          value="184"
          sub-icon="mdi-license"
          sub-icon-color="red"
          sub-text="Get More Space..."
        >
          <v-row>
            <v-col cols="12" md="3" v-if="permisosUsuarios.user_htec">
              <v-combobox
                v-model="filter.idCliente"
                :items="itemsClientesFilter"
                label="Cliente"
                chips
                item-value="id"
                item-text="descripcion"
                return-object
                dense
                hide-details="auto"
                @change="changeCliente"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="5">
              <v-combobox
                v-model="filter.idEmpresa"
                :items="itemsEmpresasFilter"
                label="Empresa"
                chips
                item-value="id"
                item-text="descripcion"
                return-object
                dense
                hide-details="auto"
                multiple
                @change="changeEmpresas"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="search" label="Buscar"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="light-blue darken-4"
                    fab
                    small
                    @click.prevent="openDialogCrearLicencia"
                    :loading="loadingLicencia"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Crear Licencia</span>
              </v-tooltip>
              &nbsp;&nbsp;
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="light-green darken-4"
                    fab
                    small
                    click.prevent="openDialogCrearLicencia"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>Exportar Excel</span>
              </v-tooltip>
              &nbsp;&nbsp;
              <v-progress-circular
                :width="3"
                color="red"
                indeterminate
                v-if="loadingOption"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-card-text>
            <v-data-table
              dense
              v-model="selectMedicamentos"
              :headers="headers"
              :items="items"
              :search="search"
              :show-select="false"
              :hide-default-header="true"
              :items-per-page="100"
              item-key="idproducto"
              class="mt-3 elevation-1"
              :single-select="false"
              :loading="loading"
            >
              
              <template v-if="search" v-slot:no-results>
                NO RESULTS HERE!
              </template>      
              
                    

              <template v-if="items.length == 0" v-slot:no-data>
                No hay registros
              </template>
              <template v-slot:header="{ props, on }">
                <thead>
                  <tr>
                    <th v-for="header in props.headers" :key="header.value">
                      <v-simple-checkbox
                        v-if="
                          header.value === 'data-table-select' &&
                          !props.singleSelect
                        "
                        v-model="props.everyItem"
                        :indeterminate="props.someItems && !props.everyItem"
                        color="purple"
                        @input="on['toggle-select-all']"
                      ></v-simple-checkbox>
                      <span
                        style="
                          color: #000 !important;
                          font-weight: bold;
                          font-size: 12px;
                        "
                        v-else
                        >{{ header.text }}</span
                      >
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item, isSelected }">
                <tr
                  :class="isSelected ? 'blue lighten-4' : ''"
                  @contextmenu="clickRightOptions($event, item)"
                >
                  <td style="width: 14% !important">
                    {{ item.cliente }} <br />
                    <span
                      style="font-size: 11px; color: #000; font-weight: bold"
                      >{{ item.empresa }}</span
                    >
                  </td>
                  <td style="width: 15% !important">
                    {{ item.licencia }} <br />
                    <span
                      style="font-size: 12px; color: #000; font-weight: bold"
                      >F.Inicio{{ item.inicio }}</span
                    ><br />
                    <span
                      style="font-size: 12px; color: #000; font-weight: bold"
                      >F.Fin {{ item.fin }}</span
                    >
                  </td>
                  <td style="width: 2% !important">
                    {{ item.plataforma }} <br />
                    <span style="color: #01579b; font-weight: bold">{{
                      item.producto
                    }}</span>
                  </td>
                  <td style="width: 4% !important">
                    {{ item.idsubproducto }}
                    <v-progress-circular
                      :width="3"
                      color="red"
                      indeterminate
                      v-if="item.loading"
                    ></v-progress-circular>                    

                  </td>
                  <td style="width: 15% !important">
                    <v-badge
                      color="green"
                      bordered
                      inline
                      left
                      :content="item.cant_permitida"
                    >
                      <span style="font-size: 10px">Permitidas</span>
                    </v-badge>
                    <br />
                    <v-badge
                      color="pink"
                      bordered
                      inline
                      right
                      :content="item.cant_usada.toString()"
                    >
                      <span style="font-size: 10px">Usadas</span>
                    </v-badge>
                  </td>

                  <td style="width: 5% !important">
                    <v-btn :class="item.color_idestado=='PE' ? 'warning':(item.color_idestado=='AP'?'primary':'error')" x-small>
                      {{ item.idestado }}
                    </v-btn>
                    
                  </td>
                  <td style="width: 5% !important">{{ item.costo }}</td>
                  <td style="width: 5% !important">
                    <v-tooltip bottom v-if="item.editado==1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="light-blue darken-4"
                          fab
                          x-small
                          @click.prevent="openDialogAmpliacionLicencia(item)"
                          :loading="loadingLicencia"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ampliación de licencia</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-dialog v-model="dialogAmpliacionLicencia" scrollable max-width="550px">
        <v-card>
          <v-card-title><strong>
          {{ titleDialogAmpliacion }}</strong></v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-timeline
              :dense="$vuetify.breakpoint.smAndDown"
              
            >
              <v-timeline-item
                v-for="(n, i) in showDataAmpliacionLicencia"
                :key="n"
              >
                <span slot="opposite">{{i.toUpperCase()}}</span>
                <v-card class="elevation-2">
                  <v-card-text>
                    {{n}}
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn color="success" @click.prevent="preguntarEstadoLicencia('APROBAR', '¿Estás seguro que deseas aprobar la licencia?', 'AP_UP')" v-if="permisosUsuarios.user_htec" text>
              APROBAR
            </v-btn>
            <v-btn color="error" @click.prevent="preguntarEstadoLicencia('RECHAZAR', '¿Estás seguro que deseas rechazar la licencia?', 'RE_UP')" v-if="permisosUsuarios.user_htec" text>
              RECHAZAR
            </v-btn>
            <v-btn color="error" @click.prevent="dialogAmpliacionLicencia = false" small>
              CERRAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCrearLicencia" scrollable max-width="550px">
        <v-card>
          <v-card-title><strong>{{titleDialogCrear}} <br>
          {{ form.idLicencia != '' ? item.empresa + ' [' + item.licencia+']': ''}}</strong></v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <template v-for="(item, index) in itemsPlataformas" >
                  <v-chip
                    :class="
                      'mr-2 ' + (form.idPlataforma == item.id ? 'primary' : '')
                    "
                    @click="tipoPlataforma(item.id)"
                    :key="index"
                    :disabled="form.idLicencia==''?false : true"
                  >
                    <v-icon left> {{item.icono}} </v-icon>
                    {{ item.id }}
                  </v-chip>
                </template>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-combobox
                  v-model="form.idEmpresa"
                  :items="itemsEmpresas"
                  label="Empresa"
                  chips
                  item-value="id"
                  item-text="descripcion"
                  return-object
                  dense
                  :disabled="form.idLicencia==''?false : true"
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-combobox
                  v-model="form.idProducto"
                  :items="itemsProductos"
                  label="Producto"
                  chips
                  item-value="id"
                  item-text="descripcion"
                  return-object
                  @change="changeProducto"
                  dense
                  :disabled="form.idLicencia==''?false : true"
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-select
                  v-model="form.idSubProducto"
                  :items="itemsSubProductos"
                  label="Sub Producto"
                  chips
                  dense
                  multiple
                  item-text="descripcion"
                  item-value="id"
                  return-object
                >        
                
                </v-select>
              </v-col>              
              
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.fechaInicio"
                      label="Fecha de inicio"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(form.fechaInicio)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu1"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.fechaFin"
                      label="Fecha de Vencimiento"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date2 = parseDate(form.fechaFin)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col offset-md="6" offset-sm="6" offset-lg="6">
                <v-text-field
                  label="Cantidad"
                  v-model="form.cantidad"
                  v-mask="['####']"
                  type="text"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialogCrearLicencia = false" :disabled="loadingRegister">
              Cancelar
            </v-btn>
            <v-btn
              color="blue darken-1"              
              @click.prevent="submitCrearLicencia"
              :loading="loadingRegister"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEstadoLicencia" scrollable max-width="350px" persistent>
        <v-card>
          <v-card-title><strong>
          {{ titleDialogLicencia }}</strong></v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            {{ descripcionDialogLicencia }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            
            <v-btn color="error" @click.prevent="dialogEstadoLicencia = false" text :loading="loadingEstadoLicencia">
              CERRAR
            </v-btn>
            <v-btn color="success" @click.prevent="guardarEstadoLicencia" v-if="permisosUsuarios.user_htec" text :loading="loadingEstadoLicencia">
              {{titleDialogLicencia}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu
          v-model="menuFiltro"
          :close-on-content-click="true"
          :close-on-click="true"
          :nudge-width="200"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list dense>
            <v-list-item @click="editarLkicencia()" v-if="!permisosUsuarios.user_htec">
              <v-list-item-icon>
                <v-icon>mdi-playlist-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="preguntarEstadoLicencia('APROBAR', '¿Estás seguro que deseas aprobar la licencia?', 'AP')" v-if="permisosUsuarios.user_htec && item.color_idestado=='PE'">
              <v-list-item-icon>
                <v-icon>mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Aprobar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="preguntarEstadoLicencia('RECHAZAR', '¿Estás seguro que deseas rechazar la licencia?', 'RE')" v-if="permisosUsuarios.user_htec">
              <v-list-item-icon>
                <v-icon>mdi-playlist-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Rechazar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="preguntarEstadoLicencia('ANULAR', '¿Estás seguro que deseas anular la licencia?', 'AN')" v-if="permisosUsuarios.user_htec">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Anular</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
          </v-list>
        </v-menu>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="color"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "DashboardPagesTimeline",

  data: (vm) => ({
    menu: false,

    headers: [
      { text: "Cliente", value: "cliente", width: "width: 160px !important;" },

      {
        text: "Licencia/F.Inicio/F.Fin",
        value: "licencia",
        width: "width: 150px !important;",
      },
      /*{
        text: "Inicio",
        value: "inicio",
        width: "width: 350px !important;",
      },
      {
        text: "Fin",
        value: "fin",
        width: "width: 350px !important;",
      },*/
      {
        text: "Plataforma/Producto",
        value: "plataforma",
        width: "width: 50px !important;",
      },
      /*{
        text: "Producto",
        value: "producto",
        width: "width: 50px !important;",
      },*/
      {
        text: "Sub Producto",
        value: "idsubproducto",
        width: "width: 50px !important;",
      },
      /*{
        text: "Cant. Permitida",
        value: "cant_permitida",
        width: "width: 50px !important;",
      },*/
      {
        text: "Cant. Permitida/Cant. Usada",
        value: "cant_usada",
        width: "width: 50px !important;",
      },

      {
        text: "Estado",
        value: "idestado",
        width: "width: 50px !important;",
      },
      {
        text: "Costo",
        value: "costo",
        width: "width: 50px !important;",
      },
      {
        text: "#",
        value: "editado",
        width: "width: 50px !important;",
      },
    ],

    addProductModal: false,
    clickRow: false,
    search: "",
    items: [],
    itemsStockByAlmacen: [],
    selectMedicamentos: [],
    xopenPrecios: false,
    loading: false,
    menuFiltro: false,
    x: 0,
    y: 0,
    loadingStock: false,

    verStockModal: false,
    titleVerStock: "",
    idProducto: "",

    itemProducto: {},
    verProductoModal: false,
    titleVerProducto: "",

    anularModal: false,
    titleAnular: "",
    bodyAnular: "",

    esLote: false,
    dialogFiltro: false,

    dialogCrearLicencia: false,
    form: {
      idLicencia: '',
      idEmpresa: "",
      idLicencia: "",
      fechaInicio: "",
      fechaFin: "",
      idPlataforma: "",
      idProducto: "",
      idSubProducto: [],
      cantidad: 0,
      fechaInicio: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      fechaFin: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    },
    itemsProductos: [],
    itemsSubProductos: [],
    itemsPlataformas: [],
    itemsEmpresas: [],
    itemsEmpresasFilter: [],
    itemsClientesFilter: [],
    filter: {
      idEmpresa: "",
      idCliente: "",
    },
    loadingLicencia: false,
    //date2: '',
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    menu1: false,
    menu2: false,
    loadingRegister: false,
    menuFiltro: false,
    x: 0,
    y: 0,
    item: {},
    loadingOption: false,
    titleDialogCrear: '',
    titleDialogAmpliacion: '',
    dialogAmpliacionLicencia: false,
    showDataAmpliacionLicencia: {},
    permisosUsuarios: {},
    dialogEstadoLicencia: false,
    titleDialogLicencia: '',
    descripcionDialogLicencia: '',
    estadoDialogLicencia: '',
    loadingEstadoLicencia: false,


    // snackbar
    color: '',
    text: '',
    snackbar: false,
  }),
  watch: {
    date (val) {
      this.form.fechaInicio = this.formatDate(this.date)
    },
    date2 (val) {
      console.log(val ,'fecha2');
      this.form.fechaFin = this.formatDate(this.date2)
    },
  },
  async created() {
    await this.listarPermisos();
    //console.log(this.permisosUsuarios.user_htec, 'permisos')
    if(this.permisosUsuarios.user_htec){
      await this.listarClientes();    
      this.filter.idCliente = this.itemsClientesFilter[0];
    }
    await this.listarEmpresas();
    let idEmpresas = "";
    /*this.itemsEmpresasFilter.map((e) => {
      idEmpresas += e.id + ",";
    });*/
    this.filter.idEmpresa = this.itemsEmpresasFilter;
    this.filter.idEmpresa.map((e) => {
      idEmpresas += e.id + ",";
    });
    idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);

    await this.listarLicencias(idEmpresas);
  },
  methods: {
    
    async guardarEstadoLicencia(){
      try {
        this.loadingEstadoLicencia = true;
        //console.log(this.item, this.estadoDialogLicencia, this.filter.idCliente.id, this.item.licencia, 'results');
        //return false;
        let body = {
          idclientex: this.filter.idCliente.id,
          idlicencia: this.item.licencia,
          tipo: this.estadoDialogLicencia,
        }
        console.log(body);
        //return false;
        //return false;
        const response = (
            await this.$xxhttp.request(
              `licencias/aceptar-estados-licencia`, 'post', body
            )
          ).data;     
        console.log(response)
        this.color = 'red';
        if(response.status){
          // snack bar
          // limpiar formulario
          
          // cerrar el formulario
          this.dialogEstadoLicencia = false;
          this.color = 'blue';
        }
        this.snackbar = true;
        this.text = response.message;
        
        this.loadingEstadoLicencia = false;
        //this.initForm();
        
        let idEmpresas = "";
        /*this.itemsEmpresasFilter.map((e) => {
          idEmpresas += e.id + ",";
        });*/
        this.filter.idEmpresa = this.itemsEmpresasFilter;
        this.filter.idEmpresa.map((e) => {
          idEmpresas += e.id + ",";
        });
        idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);

        await this.listarLicencias(idEmpresas);

      } catch (e) {
        this.loadingEstadoLicencia = false;
        console.log(e)
      }
    },
    preguntarEstadoLicencia(title = '', descripcion = '',  estado = ''){
      // llamar al modal
      this.titleDialogLicencia = title;
      this.descripcionDialogLicencia = descripcion;
      this.estadoDialogLicencia = estado;
      
      this.dialogEstadoLicencia = true;
    },
    async changeCliente(){
      try {
        const idCliente = this.filter.idCliente.id;

        this.loading = true;
        await this.listarEmpresas();
        
        let idEmpresas = "";
        this.filter.idEmpresa = this.itemsEmpresasFilter;
        this.filter.idEmpresa.map((e) => {
          idEmpresas += e.id + ",";
        });
        idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);

        await this.listarLicencias(idEmpresas);
        this.loading = false;
      } catch (e) {
        console.log(e)
      }
    },
    
    async listarPermisos() {
      try {
        const response = (
          await this.$xxhttp.request(
            `permisos`
          )
        ).data;
        this.permisosUsuarios = response.status ? response.data : {};
      } catch (e) {
        console.log(e);
      }
    },
    async openDialogAmpliacionLicencia(item){
      try {
        this.titleDialogAmpliacion = item.empresa + ' [' + item.licencia + ']';
        this.item = item;
        
        this.loadingOption = true;

        //console.log(`licencias/seguimiento-licencia?idlicencia=${item.licencia}&idempresa=${item.idempresa}&idproducto=${item.producto}&idclientex=${this.filter.idCliente.id}`)

        // consultaremos la informacion a la api
        const response = (
          await this.$xxhttp.request(
            `licencias/seguimiento-licencia?idlicencia=${item.licencia}&idempresa=${item.idempresa}&idproducto=${item.producto}&idclientex=${this.filter.idCliente.id}`
          )
        ).data;
        this.loadingOption = false;
        this.showDataAmpliacionLicencia = response.status ? response.data : {}
        this.dialogAmpliacionLicencia = true;
        console.log(response)
      } catch (e) {
        this.loadingOption = false;
        console.log(e)
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async submitCrearLicencia(){
      try {
        // valiaremos
        if(this.form.idEmpresa == '' || this.form.idEmpresa == null || this.form.idEmpresa == undefined || this.form.idEmpresa.id == ''){
          this.color = 'red';
          this.text = 'Ingrese una empresa';
          this.snackbar = true;
          return false;
        }
        
        if(this.form.idProducto == '' || this.form.idProducto == null || this.form.idProducto == undefined || this.form.idProducto.id==''){
          this.color = 'red';
          this.text = 'Ingrese un producto';
          this.snackbar = true;
          return false;
        }

        
        if(this.form.idSubProducto == '' || this.form.idSubProducto == null || this.form.idSubProducto == undefined || this.form.idSubProducto.id == ''){
          this.color = 'red';
          this.text = 'Seleccione al menos un sub producto';
          this.snackbar = true;
          return false;
        }

        const d1 = new Date(this.parseDate(this.form.fechaInicio));
        const d2 = new Date(this.parseDate(this.form.fechaFin));

        if(d1 >= d2){
          this.color = 'red';
          this.text = 'La fecha de inicio es mayor a la fecha de Vencimiento';
          this.snackbar = true;
          return false;
        }

        //const idCliente = '1'; // obsoleto
        const idCliente = this.filter.idCliente.id;
        let idSubs = ''
        this.form.idSubProducto.map(e => {
          idSubs += e.id + ','
        });
        idSubs = idSubs.substring(0, idSubs.length-1);

        this.loadingRegister = true;
        let body = {
          idclientex: idCliente,
          idlicencia: this.form.idLicencia,
          idplataforma: this.form.idPlataforma,
          idempresas: this.form.idEmpresa.id,
          idproducto: this.form.idProducto.id,
          idsubproducto: idSubs,
          cantidad: this.form.cantidad,
          fechainicio: this.parseDate(this.form.fechaInicio),
          fechafin: this.parseDate(this.form.fechaFin),
        }
        console.log(body,'body enviado')
        //return false;
        let response;
        if(this.form.idLicencia == ''){
          response = (
            await this.$xxhttp.request(
              `licencias`, 'post', body
            )
          ).data;
          this.initFormxx();
        }else{
          response = (
            await this.$xxhttp.request(
              `licencias`, 'put', body
            )
          ).data;
        }
        
        this.color = 'red';
        if(response.status){
          // snack bar
          // limpiar formulario
          
          // cerrar el formulario
          this.dialogCrearLicencia = false;
          this.color = 'blue';
        }
        this.snackbar = true;
        this.text = response.message;
        
        this.loadingRegister = false;
        //this.initForm();
        
        let idEmpresas = "";
        /*this.itemsEmpresasFilter.map((e) => {
          idEmpresas += e.id + ",";
        });*/
        this.filter.idEmpresa = this.itemsEmpresasFilter;
        this.filter.idEmpresa.map((e) => {
          idEmpresas += e.id + ",";
        });
        idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);

        await this.listarLicencias(idEmpresas);
        
        console.log(response, 'finish')
      } catch (e) {
        console.log(e)
        this.loadingRegister = false;
      }
    },
    initFormxx(){
      this.form ={
        idEmpresa: null,
        idLicencia: "",
        fechaInicio: "",
        fechaFin: "",
        idPlataforma: "",
        idProducto: "",
        idSubProducto: "",
        cantidad: 0,
        fechaInicio: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        fechaFin: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      }
      // popus
      this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      this.date2 = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    },
    clickRightOptions(e, item = {}) {
      e.preventDefault();
      this.menuFiltro = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menuFiltro = true;
      });
      this.item = item;

      console.log(this.item)
    },
    async changeEmpresas(evt) {
      let idEmpresas = "";
      this.filter.idEmpresa.map((e) => {
        idEmpresas += e.id + ",";
      });
      idEmpresas = idEmpresas.substring(0, idEmpresas.length - 1);
      await this.listarLicencias(idEmpresas);
    },
    async changeProducto() {
      this.form.idSubProducto = null;
      await this.listarSubProductos();
      //this.form.idSubProducto = (this.itemsSubProductos.length > 0) ? this.itemsSubProductos[0]:[];
      this.form.idSubProducto = (this.itemsSubProductos.length > 0) ? [this.itemsSubProductos[0]]:[];
    },
    async tipoPlataforma(val) {
      this.form.idPlataforma = val;
      await this.listarProductos();
      this.form.idProducto = (this.itemsProductos.length > 0) ? this.itemsProductos[0] : [];
      this.form.idSubProducto = [];
      await this.listarSubProductos();
      //this.form.idSubProducto = this.itemsSubProductos;
      this.form.idSubProducto = (this.itemsSubProductos.length > 0) ? [this.itemsSubProductos[0]] : [];
    },
    async openDialogCrearLicencia() {
      this.titleDialogCrear = 'CREAR LICENCIA';
      this.initFormxx();
      this.loadingLicencia = true;
      await this.listarPlataformas();
      this.form.idPlataforma = this.itemsPlataformas[0].id;
      this.dialogCrearLicencia = true;
      this.loadingLicencia = false;
      await this.listarEmpresas();
      await this.listarProductos();
      this.form.idProducto = this.itemsProductos[1];
      await this.listarSubProductos();
    },
    async editarLkicencia(){
      try {
        const item = this.item;
        
        this.titleDialogCrear = 'EDITAR LICENCIA';
        const index = this.items.indexOf(item);
        this.items[index].loading = true;
        //this.loadingOption = true;
        await this.listarPlataformas();        
        this.form.idPlataforma = this.itemsPlataformas.filter(e => e.id==item.plataforma)[0].id;        
        await this.listarEmpresas();
        this.form.idEmpresa = this.itemsEmpresas.filter(e => e.id==item.idempresa)[0];
        await this.listarProductos();
        this.form.idProducto = this.itemsProductos.filter(e => e.id==item.producto)[0];
        await this.listarSubProductos();
        this.dialogCrearLicencia = true;
        let idsSubs = item.idsubproducto;
        idsSubs = idsSubs.split(',');
        idsSubs = idsSubs.reduce((a, v) => ({ ...a, [v]: v}), {});
        this.form.idLicencia = item.licencia;
        this.form.idSubProducto = this.itemsSubProductos.filter(e => idsSubs[e.id]==e.id);
        this.form.fechaInicio = this.formatDate(item.edit_inicio);
        this.form.fechaFin = this.formatDate(item.edit_fin);
        // cargaremos los popus de los dates
        this.date = item.edit_inicio;
        this.date2 = item.edit_fin;
        //this.form.datePickerEnd = item.edit_fin;
        this.form.cantidad = item.cant_permitida;
        //this.loadingOption = false;
        this.items[index].loading = false;
      } catch (e) {
        this.loadingOption = false;
        console.log(e, 'error')
      }
    },
    async listarLicencias(filterEmpresas = "") {
      try {
        const idCliente = this.filter.idCliente.id;
        
        const idEmpresas = filterEmpresas;
        console.log(idCliente, 'idcliente', idEmpresas)
        this.loading = true;
        const response = (
          await this.$xxhttp.request(
            `licencias?idclientex=${idCliente}&idempresas=${idEmpresas}`
          )
        ).data;

        // 933251757

        this.items = response.status ? response.data : [];
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async listarEmpresas() {
      try {
        let idCliente = '';
        let url = 'licencias/empresas';
        if(this.permisosUsuarios.user_htec){
          idCliente = this.filter.idCliente.id;
          url = `${url}?idclientex=${idCliente}`;
        }        

        const response = (
          await this.$xxhttp.request(url)
        ).data;
        this.itemsEmpresas = response.status ? response.data : [];
        this.itemsEmpresasFilter = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
    async listarPlataformas() {
      try {
        const response = (await this.$xxhttp.request(`licencias/plataformas`))
          .data;
        this.itemsPlataformas = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
    async listarProductos() {
      try {
        const response = (
          await this.$xxhttp.request(
            `licencias/productos?idplataforma=${this.form.idPlataforma}`
          )
        ).data;
        this.itemsProductos = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
    async listarSubProductos() {
      try {
        const response = (
          await this.$xxhttp.request(
            `licencias/subproductos?idplataforma=${this.form.idPlataforma}&idproducto=${this.form.idProducto.id}`
          )
        ).data;
        this.itemsSubProductos = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
    async listarClientes() {
      try {
        const response = (
          await this.$xxhttp.request(
            `licencias/clientes`
          )
        ).data;
        this.itemsClientesFilter = response.status ? response.data : [];
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style>
/*.v-text-field input{
  -webkit-box-flex: 1;
    line-height: 18px;
    padding: 2px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}*/
.v-input input {
  max-height: 28px !important;
}
</style>