<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card color="blue" class="px-5 py-3" icon="mdi-format-list-bulleted" title="Preferencias"
          value="184" sub-icon="mdi-format-list-bulleted" sub-icon-color="red" sub-text="Get More Space...">
          <v-row>
            <v-col cols="12" md="3" v-if="permisosUsuarios.user_htec">
              <v-combobox v-model="filter.idCliente" :items="itemsClientesFilter" label="Cliente" chips item-value="id"
                item-text="descripcion" return-object dense hide-details="auto" @change="changeCliente">
              </v-combobox>
            </v-col>
            <v-col cols="12" md="5">
              <v-combobox v-model="filter.idEmpresa" :items="itemsEmpresasFilter" label="Empresa" chips item-value="id"
                item-text="descripcion" return-object dense hide-details="auto" multiple @change="changeEmpresas">
              </v-combobox>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="search" label="Buscar"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="light-blue darken-4"
                    fab
                    small
                    @click="crearPreferencias()"
                    :loading="loadingLicencia"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Crear Preferencia</span>
              </v-tooltip>
              &nbsp;&nbsp;
            </v-col>
          </v-row>

          <v-card-text>
            <v-data-table dense v-model="selectMedicamentos" :headers="headers" :items="items" :search="search"
              :show-select="false" :hide-default-header="true" :items-per-page="100" item-key="idproducto" class="mt-3"
              :single-select="false" :loading="loading">

              <template v-if="search" v-slot:no-results>
                NO RESULTS HERE!
              </template>

              <template v-if="items.length == 0" v-slot:no-data>
                No hay registros
              </template>
              <template v-slot:header="{ props, on }">
                <thead>
                  <tr>
                    <th v-for="header in props.headers" :key="header.value" :style="header.width">
                      <v-simple-checkbox v-if="
                          header.value === 'data-table-select' &&
                          !props.singleSelect
                        " v-model="props.everyItem" :indeterminate="props.someItems && !props.everyItem" color="purple"
                        @input="on['toggle-select-all']"></v-simple-checkbox>
                      <span style="
                          color: #000 !important;
                          font-weight: bold;
                          font-size: 12px;
                        " v-else>{{ header.text }}</span>
                    </th>
                  </tr>

                </thead>
              </template>
              <template v-slot:item="{ item, isSelected }">
                <tr :class="isSelected ? 'blue lighten-4' : ''" @contextmenu="clickRightOptions($event, item)">
                  <td style="width: 20% !important">{{ item.idpreference }}</td>
                  <td style="width: 20% !important">{{ item.idcliente }}</td>
                  <td style="width: 20% !important">{{ item.idempresa }}</td>
                  <td style="width: 20% !important">{{ item.idplataforma }}</td>
                  <td style="width: 20% !important">...</td>

                </tr>
              </template>
            </v-data-table>

            <v-dialog v-model="dialogPreference" max-width="700" @keydown.esc="dialogPreference=false"
              @click:outside="dialogPreference=false">
              <v-card>
                <v-card-title class="primary white--text">
                  <span class="headline">Detalle de Preferencias</span>
                </v-card-title>
                <v-card-text class="pt-6">
                  <h5> Cliente: {{ itemSeleccionado.cliente }}</h5>
                  <h5> Empresa: {{ itemSeleccionado.empresa }}</h5>
                  <h5> Plataforma: {{ itemSeleccionado.idplataforma }}</h5>
                  <h5> Preferencias: {{ itemSeleccionado.preference }}</h5>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="dialogPreference=false">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

           <v-dialog v-model="dialogCrearPreference" max-width="700" @keydown.esc="dialogCrearPreference=false,creating=false"
              @click:outside="dialogCrearPreference=false,creating=false">
              <v-card>
                <v-card-title class="primary white--text">
                  <strong>{{titleDialogCrear}}</strong>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text >
                    <v-combobox v-model="IdCliente" :items="itemsClientesFilter" label="Cliente" chips item-value="id"
                        item-text="descripcion" return-object dense hide-details="auto" @change="CambiarModo(),changeCliente()">
                     </v-combobox>
                    <v-combobox v-model="IdEmpresa" :items="itemsEmpresasFilter" label="Empresa" chips item-value="id"
                        item-text="descripcion" return-object dense hide-details="auto" multiple @change="changeEmpresas">
                    </v-combobox>
                  <v-combobox v-model="idplataforma" :items="itemsPlataformas" label="Plataformas" chips
                    item-value="id" item-text="descripcion" return-object dense hide-details="auto">
                  </v-combobox>

                  <v-textarea v-model="preference" label="Preferencia"></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="dialogCrearPreference=false" :disabled="loadingRegister"  @click.prevent="CambiarModo2()">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" @click.prevent="CrearPreference()" :loading="loadingRegister">
                    Aceptar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


            <v-dialog v-model="dialogEditarPreference" max-width="700" @keydown.esc="dialogEditarPreference=false"
              @click:outside="dialogEditarPreference=false">
              <v-card>
                <v-card-title class="primary white--text">
                  <strong>{{titleDialogEditar}}</strong>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="item">

                  <v-combobox v-model="item.cliente" :items="itemsClientesFilter" label="Cliente" chips item-value="id"
                    item-text="descripcion" return-object hide-details="auto" :disabled="true">
                  </v-combobox>
                  <v-combobox v-model="item.empresa" :items="itemsEmpresasFilter" label="Empresa" chips item-value="id"
                    item-text="descripcion" return-object hide-details="auto" :disabled="true">
                  </v-combobox>
                  <v-combobox v-model="item.idplataforma" :items="itemsPlataformas" label="Plataformas" chips
                    item-value="id" item-text="descripcion" return-object hide-details="auto">
                  </v-combobox>

                  <v-textarea v-model="item.preference" label="Preferencia"></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="error" @click="dialogEditarPreference=false" :disabled="loadingRegister">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" @click.prevent="EditarPreference(item)" :loading="loadingRegister">
                    Aceptar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>



          </v-card-text>
        </base-material-card>
      </v-col>
      <v-menu v-model="menuFiltro" :close-on-content-click="true" :close-on-click="true" :nudge-width="200"
        :position-x="x" :position-y="y" absolute offset-y>
        <v-list dense>
          <template>
            <v-list-item @click="editarPreferencias()" v-if="permisosUsuarios.user_htec">
              <v-list-item-icon>
                <v-icon>mdi-playlist-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!--<v-list-item @click="eliminarPreferencias" v-if="permisosUsuarios.user_htec">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Eliminar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->
            <v-list-item @click="verPreference" v-if="permisosUsuarios.user_htec">
              <v-list-item-icon>
                <v-icon>mdi-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ver</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "DashboardPagesTimeline",

    data: () => ({
      menu: false,
      itemsPlataforma: [{
          text: "WEB",
          value: "WEB"
        },
        {
          text: "DESK",
          value: "DESK"
        },
        {
          text: "MOBILE",
          value: "MOBILE"
        },

      ],
      headers: [{
          text: "Id Preferencia",
          value: "idpreferencia",
          width: "width: 100px !important;"
        },

        {
          text: "Id Cliente",
          value: "idcliente",
          width: "width: 100px !important;"
        },
        {
          text: "Id Empresa",
          value: "idempresa",
          width: "width: 100px !important;",
        },
        {
          text: "Id Plataforma",
          value: "idplataforma",
          width: "width: 100px !important;",
        },
        {
          text: "Preferencia",
          value: "idpreference",
          width: "width: 100px !important;",
        },

      ],
      loadingTable: false,
      loadingRegister: false,
      titleDialogCrear: '',
      titleDialogEditar:'',
      empresa: '',
      preferences: [],
      headerPreferences: [{
          text: "Cliente",
          value: "idpreference",
          width: '150'
        },
        {
          text: "Empresa",
          value: "idpreference",
          width: '150'
        },
        {
          text: "Plataforma",
          value: "idpreference",
          width: '150'
        },
        {
          text: "Preferencia",
          value: "idpreference",
          width: '150'
        },

      ],
      creating:false,
      dialogPreference: false,
      addProductModal: false,
      clickRow: false,
      search: "",
      items: [],
      itemsStockByAlmacen: [],
      selectMedicamentos: [],
      xopenPrecios: false,
      loading: false,
      menuFiltro: false,
      x: 0,
      y: 0,
      loadingStock: false,
      itemSeleccionado: "",
      verStockModal: false,
      titleVerStock: "",
      idProducto: "",

      itemProducto: {},
      verProductoModal: false,
      titleVerProducto: "",

      anularModal: false,
      titleAnular: "",
      bodyAnular: "",

      esLote: false,
      dialogFiltro: false,
      itemsPlataformas: [],

      itemsEmpresas: [],
      itemsEmpresasFilter: [],
      itemsClientesFilter: [],
      filter: {
        idEmpresa: "",
        idCliente: "",
      },
      permisosUsuarios: {},
      loadingLicencia: false,
      loadingOption: false,
      loading: false,
      item: null,
      itemCrear: null,
      dialogCrearPreference: false,
      dialogEditarPreference: false,
      loadingRegister: false,
      IdCliente: null,
      IdEmpresa: null,
      idplataforma: null,
      preference: null
    }),
    async created() {
      await this.listarPermisos();
      await this.listarPlataformas();
      //console.log(this.permisosUsuarios.user_htec, 'permisos')
      if (this.permisosUsuarios.user_htec) {
        await this.listarClientes();
        this.filter.idCliente = this.itemsClientesFilter[0];
        await this.listarEmpresas();
        await this.listarPreferencias();
      }
      

      
    },
    methods: {
      clickRightOptions(e, item = {}) {
        e.preventDefault();
        this.menuFiltro = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
          this.menuFiltro = true;
        });
        this.item = item;

        console.log(this.item)
      },
      verPreference(item) {
        this.itemSeleccionado = this.item;
        this.dialogPreference = true;
        this.idpreference = this.item.id;
        /*this.idpreference = this.item.id;
        this.idempresa = this.item.idempresa;
        this.idcliente = this.item.idcliente;*/
        this.estadoMessage = false;


      },
      async listarPermisos() {
        try {
          const response = (
            await this.$xxhttp.request(
              `permisos`
            )
          ).data;
          this.permisosUsuarios = response.status ? response.data : {};
        } catch (e) {
          console.log(e);
        }
      },

      async listarPlataformas() {
        try {
          const response = (await this.$xxhttp.request(`licencias/plataformas`))
            .data;
          this.itemsPlataformas = response.status ? response.data : [];
        } catch (e) {
          console.log(e);
        }
      },
      async listarClientes() {
        try {
          const response = (
            await this.$xxhttp.request(
              `licencias/clientes`
            )
          ).data;
          this.itemsClientesFilter = response.status ? response.data : [];
        } catch (e) {
          console.log(e);
        }
      },
      async listarEmpresas() {
        try {
          let idCliente = '';
          let url = 'licencias/empresas';
          if (this.permisosUsuarios.user_htec) {
          // idCliente = this.filter.idCliente.id;
           console.log(this.creating);
            if(this.creating==true){
               idCliente = this.IdCliente.id;
            }else{
               idCliente = this.filter.idCliente.id;
            }
            //idCliente = this.filter.idCliente.id;
            url = `${url}?idclientex=${idCliente}`;
          }

          const response = (
            await this.$xxhttp.request(url)
          ).data;
          console.log(this.itemsEmpresas);
          console.log(this.itemsEmpresasFilter);
          this.itemsEmpresas = response.status ? response.data : [];
          this.itemsEmpresasFilter = response.status ? response.data : [];
        } catch (e) {
          console.log(e);
        }
      },
      async changeEmpresas(evt) {
         if(this.creating==false){
               await this.listarPreferencias(); 
          }
          
      },
      async CambiarModo() {
         this.creating=true;
      },
      async CambiarModo2() {
         this.creating=false;
      },
      async changeCliente() {
        try {
          this.loading = true;
          console.log(this.creating);
          await this.listarEmpresas();
          //this.creating = false;
           console.log(this.creating);
          if(this.creating==false){
               await this.listarPreferencias(); 
          }

          this.loading = false;
        } catch (e) {
          console.log(e)
        }
      },

      async listarPreferencias() {
        try {
          console.log(this.creating);
          let filterEmpresas = "";
          this.filter.idEmpresa = this.itemsEmpresasFilter;
          this.filter.idEmpresa.map((e) => {
            filterEmpresas += e.id + ",";
          });
          filterEmpresas = filterEmpresas.substring(0, filterEmpresas.length - 1);

          const idCliente = this.filter.idCliente.id;
          this.loading = true;
          const idEmpresas = filterEmpresas;
          console.log(idCliente, idEmpresas,)
          this.loading = true;
          const response = (
            await this.$xxhttp.request(
              `preferencias?idclientex=${idCliente}&idempresas=${idEmpresas}`
            )
          ).data;
          this.items = response.status ? response.data : [];
          this.loading = false;
          console.log(this.items)
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      },
      async crearPreferencias() {

        console.log('Crear Preferencias ');
        this.titleDialogCrear = 'CREAR PREFERENCIA';
        this.dialogCrearPreference = true;

      },
       async CrearPreference() {
        try {
          //  let token = this.$store.getters.token;
          this.loadingRegister = true;
          const idCliente = this.IdCliente.id;
          const preference = this.preference;
          const idplataforma = this.idplataforma.id;

          let idEmp = ''
          this.IdEmpresa.map(e => {
          idEmp += e.id + ','
           });
          idEmp = idEmp.substring(0, idEmp.length-1);
          // console.log(idCliente);
          // console.log(idEmp);
          // console.log(preference);
          // console.log(idplataforma);
          let body = {
            "idclientex": idCliente,
            "idempresa": idEmp,
            "idplataforma": idplataforma,
            "preference": preference,
          }
           console.log(body);
         const response = (
            await this.$xxhttp.request(
              `creating`, 'post', body
            )
          ).data;
          console.log(response);
          this.items = response.status ? response.data : [];
          this.dialogCrearPreference = false;
          this.CambiarModo2();
          this.loadingRegister = false;
          await this.listarPreferencias();

          
        } catch (e) {
          this.loadingRegister = false;
          console.log(e, 'error')
        }

      },
      async editarPreferencias() {

        console.log('Editar Preferencias ');
        this.titleDialogEditar = 'EDITAR PREFERENCIA';
        this.dialogEditarPreference = true;

      },

      async EditarPreference(item) {
        try {
          //  let token = this.$store.getters.token;
          this.loadingRegister = true;
          const idCliente = item.idcliente;
          const idempresa = item.idempresa;
          const idplataforma = item.idplataforma.id;
          const preference = item.preference;
          const idpreference = item.idpreference

          let body = {
            "idclientex": idCliente,
            "idempresa": idempresa,
            "idplataforma": idplataforma,
            "preference": preference,
            "idpreference": idpreference,

          }
          const response = (
            await this.$xxhttp.request(
              `editarpreferencias`, 'post', body
            )
          ).data;

          console.log(response);
          this.items = response.status ? response.data : [];
          this.dialogEditarPreference = false;
          await this.listarPreferencias();

          this.loadingRegister = false;
        } catch (e) {
          this.loadingRegister = false;
          console.log(e, 'error')
        }

      },

      async eliminarPreferencias() {
        console.log(' Eliminar Preferencias ');
      }

    },
  };

</script>
