import Vue from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';

import store from '../store/index';

const request = (url, method = 'get', ...params) => {
  // configuramos todos los metodos de vuejs
  const options = {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
      'Access-Control-Allow-Credentials': 'true',
    },
  };

  // const token = store.getters['auth/getToken']
  /* console.log('token1', store.getters.isAuthenticated2)
  if (store.getters.isAuthenticated2) {
    options.headers = {
      Authorization: `Bearer ${store.getters.getToken}`,
    };
    console.log('token2', store.getters.getToken, store.getters.isAuthenticated2)
  } */

  // console.log(store.getters.getTokeen, 'store')

  const instance = axios.create(options);

  // instance.defaults.headers.common['Authorization'] = 'Bearer hfheheh';
  // instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

  instance.interceptors.request.use(
    (request) => {
      // console.log('token1', store.getters.isAuthenticated2)
      if (store.getters.isAuthenticated2) {
        request.headers.Authorization = `Bearer ${store.getters.getToken.trim()}`;

        // console.log('token2', store.getters.getToken, store.getters.isAuthenticated2)
      }

      return request;
    },
    (error) => {
      console.log('ERROR REQUEST ===', error);
      if (error.response.status === 401) {
        // window.location.href = '/';
      }

      console.log('error request');
      // Promise.reject(requestError)
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // CONTROLAR LOS CODIGOS HTTP
      if (error.response && error.response.status === 401) {
        await Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: `OK`,
        });
        localStorage.clear();
        window.location.href = '/';
      } else if (error.response && error.response.status === 500) {
        await Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: `OK`,
        });
        //window.location.href = '/';
      }
      // 404
      // 403
      // 500
      // console.log(error.response.data.message, 'message', )

      // throw new Error(error.response ? error.response : '');
      // Promise.reject(error)
      // return error.response ? error.response : Promise.reject(new Error(error));
    }
  );

  // console.log('axiotoken', options)
  let results = null;
  const prefix = '';
  switch (method) {
    case 'get':
      results = instance.get(`${prefix}${url}`);
      // .then(response => Promise.resolve(response))
      // .catch(error => Promise.reject(error));
      // console.log('rr',results)
      break;
    case 'post':
      console.log('post');
      results = instance.post(`${prefix}${url}`, params[0], params[1]);
      // .then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
      break;
    case 'put':
      results = instance
        .put(`${prefix}${url}`, params[0])
        .then((response) => Promise.resolve && response)
        .catch((error) => Promise.reject(error));
      break;
    case 'patch':
      results = instance
        .patch(`${prefix}${url}`, params[0])
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
      break;
    default:
      break;
  }

  return results;
};

export default { request };
