<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card
          color="blue"
          class="px-5 py-3"
          icon="mdi-access-point-check"
          title="Solicitud de Accesos"
          value="184"
          sub-icon="mdi-access-point-check"
          sub-icon-color="red"
          sub-text="Get More Space..."
        >
          <v-row>
            <v-col cols="12" md="2">
              <span>F.INICIO</span>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.fechainicio"
                    label="Fecha Inicio"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(filter.fechainicio)"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <span>F.FIN</span>
              <v-menu
                ref="menu1"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.fechafin"
                    label="Fecha Fin"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date2 = parseDate(filter.fechafin)"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date2"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <span>ESTADO</span>
              <v-select
                v-model="filter.idestado"
                :items="itemsEstado"
                item-value="id"
                item-text="descripcion"
                return-object
                label="ESTADO"
                solo
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <span>BUSCAR</span>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                solo
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                class="primary mt-4"
                fab
                small
                @click.prevent="listarSolicitudAccesos"
              >
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-btn 
                class="primary mt-4" 
                fab 
                small
                @click.prevent="exportarSolicitudAccesos"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text>
            <v-data-table
              dense
              v-model="selectMedicamentos"
              :headers="headers"
              :items="items"
              :search="search"
              :show-select="false"
              :hide-default-header="true"
              :items-per-page="100"
              item-key="idproducto"
              class="mt-3"
              :single-select="false"
              :loading="loading"
            >
              <template v-if="search" v-slot:no-results>
                NO RESULTS HERE!
              </template>

              <template v-if="items.length == 0" v-slot:no-data>
                No hay registros
              </template>
              <template v-slot:header="{ props, on }">
                <thead>
                  <tr>
                    <th
                      v-for="header in props.headers"
                      :key="header.value"
                      :style="header.width"
                    >
                      <v-simple-checkbox
                        v-if="
                          header.value === 'data-table-select' &&
                          !props.singleSelect
                        "
                        v-model="props.everyItem"
                        :indeterminate="props.someItems && !props.everyItem"
                        color="purple"
                        @input="on['toggle-select-all']"
                      ></v-simple-checkbox>
                      <span
                        style="
                          color: #000 !important;
                          font-weight: bold;
                          font-size: 12px;
                        "
                        v-else
                        >{{ header.text }}</span
                      >
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item="{ item, isSelected }">
                <tr
                  :class="isSelected ? 'blue lighten-4' : ''"
                  @contextmenu="clickRightOptions($event, item)"
                >
                  <td style="width: 2% !important">{{ item.idplataforma }}</td>
                  <td style="width: 5% !important">{{ item.empresa }}</td>
                  <td style="width: 2% !important">{{ item.iddocumento }}</td>
                  <td style="width: 15% !important">{{ item.solicitante }}</td>
                  <td style="width: 8% !important">{{ item.email }}</td>
                  <td style="width: 4% !important">
                    {{ item.fecha_solicitud }}
                  </td>
                  <td style="width: 2% !important">
                    {{ item.idestado }}
                  </td>
                  <td style="width: 10% !important">
                    {{ item.user_aprobacion }}
                  </td>
                  <td style="width: 10% !important">
                    {{ item.user_anulacion }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-menu
        v-model="menuFiltro"
        :close-on-content-click="true"
        :close-on-click="true"
        :nudge-width="200"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item
            @click.prevent="
              preguntarEstadoLicencia(
                'APROBAR',
                '¿Estás seguro que deseas aprobar la solicitud?',
                'AP'
              )
            "
            v-if="
              ((permisosUsuarios.esadmin &&
                permisosUsuarios.solicitalicencias) ||
                (permisosUsuarios.essubadmin &&
                  permisosUsuarios.solicitalicencias)) &&
              item.idestado == 'PE'
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-playlist-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>APROBAR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="
              ((permisosUsuarios.esadmin &&
                permisosUsuarios.solicitalicencias) ||
                (permisosUsuarios.essubadmin &&
                  permisosUsuarios.solicitalicencias)) &&
              (item.idestado == 'PE' || item.idestado == 'RA')
            "
            @click.prevent="
              preguntarEstadoLicencia(
                'REACTICAR',
                '¿Estás seguro que deseas reactivar la solicitud?',
                'RA'
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>REACTIVAR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="
              ((permisosUsuarios.esadmin &&
                permisosUsuarios.solicitalicencias) ||
                (permisosUsuarios.essubadmin &&
                  permisosUsuarios.solicitalicencias)) &&
              (item.idestado == 'PE' || item.idestado == 'AP')
            "
            @click.prevent="
              preguntarEstadoLicencia(
                'RECHAZAR',
                '¿Estás seguro que deseas rechazar la solicitud?',
                'RE'
              )
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>RECHAZAR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog
        v-model="dialogEstadoAprobacion"
        scrollable
        max-width="350px"
        persistent
      >
        <v-card>
          <v-card-title
            ><strong> {{ titleDialogLicencia }}</strong></v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            {{ descripcionDialogLicencia }}
            <v-textarea
              label="Detalle adicional"
              v-model="form.observacion"
              dense
              outlined
            ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-btn
              color="error"
              @click.prevent="dialogEstadoAprobacion = false"
              text
              :loading="loadingEstadoAprobacion"
            >
              CERRAR
            </v-btn>
            <v-btn
              color="success"
              @click.prevent="guardarEstadoSolicitud"
              text
              :loading="loadingEstadoAprobacion"
            >
              {{ titleDialogLicencia }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn :color="color" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "DashboardPagesTimeline",

  data: (vm) => ({
    menu: false,

    headers: [
      {
        text: "Plataforma",
        value: "idplataforma",
        width: "width: 160px !important;",
      },

      {
        text: "Empresa",
        value: "empresa",
        width: "width: 350px !important;",
      },
      {
        text: "Nro. Doc.",
        value: "iddocumento",
        width: "width: 350px !important;",
      },
      {
        text: "Solicitante",
        value: "solicitante",
        width: "width: 50px !important;",
      },
      {
        text: "Email",
        value: "email",
        width: "width: 50px !important;",
      },
      {
        text: "F. Solicitud",
        value: "fecha_solicitud",
        width: "width: 50px !important;",
      },
      {
        text: "Estado",
        value: "idestado",
        width: "width: 50px !important;",
      },
      {
        text: "Usuario Aprobacion",
        value: "user_aprobacion",
        width: "width: 50px !important;",
      },
      {
        text: "Usuario Anulación",
        value: "user_anulacion",
        width: "width: 50px !important;",
      },
    ],

    filter: {      
      idestado: "",
      fechainicio: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      fechafin: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    },

    itemsEstado: [
      {
        id: "PE",
        descripcion: "PENDIENTE",
      },
      {
        id: "RE",
        descripcion: "RECHAZADOS",
      },
      {
        id: "AP",
        descripcion: "APROBADOS",
      },
    ],

    addProductModal: false,
    clickRow: false,
    search: "",
    items: [],
    itemsStockByAlmacen: [],
    selectMedicamentos: [],
    xopenPrecios: false,
    loading: false,
    menuFiltro: false,
    x: 0,
    y: 0,
    loadingStock: false,

    verStockModal: false,
    titleVerStock: "",
    idProducto: "",

    itemProducto: {},
    verProductoModal: false,
    titleVerProducto: "",

    anularModal: false,
    titleAnular: "",
    bodyAnular: "",

    esLote: false,
    dialogFiltro: false,
    item: {},
    menuFiltro: false,
    x: 0,
    y: 0,
    titleDialogLicencia: "",
    descripcionDialogLicencia: "",
    estadoDialogLicencia: "",
    loadingEstadoAprobacion: false,
    dialogEstadoAprobacion: false,

    form: {
      observacion: "",
    },
    permisosUsuarios: {},

    // snackbar
    color: "",
    text: "",
    snackbar: false,
    loading: false,

    // filtros
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    menu1: false,
    menu2: false,
  }),
  watch: {
    date (val) {
      this.filter.fechainicio = this.formatDate(this.date)
    },
    date2 (val) {
      console.log(val ,'fecha2');
      this.filter.fechafin = this.formatDate(this.date2)
    },
  },
  async created() {
    await this.listarPermisos();
    //console.log(this.permisosUsuarios.user_htec, 'permisos')
    //if(this.permisosUsuarios.user_htec){
    //await this.listarClientes();
    //this.filter.idCliente = this.itemsClientesFilter[0];
    //}
    this.filter.idestado = this.itemsEstado[0];
    await this.listarSolicitudAccesos();
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async listarPermisos() {
      try {
        const response = (await this.$xxhttp.request(`permisos`)).data;
        this.permisosUsuarios = response.status ? response.data : {};
      } catch (e) {
        console.log(e);
      }
    },
    async guardarEstadoSolicitud() {
      try {
        this.loadingEstadoAprobacion = true;
        let body = {
          idmovinstallationacces: this.item.idmovinstallationacces,
          tipo: this.estadoDialogLicencia,
          comentario: this.form.observacion,
        };

        console.log(body);

        const response = (
          await this.$xxhttp.request(
            `solicitud-accesos/estados-aprobacion`,
            "post",
            body
          )
        ).data;
        console.log(response);
        this.color = "red";
        if (response.status) {
          // snack bar
          // limpiar formulario

          // cerrar el formulario
          this.dialogEstadoAprobacion = false;
          this.color = "blue";
        }
        this.snackbar = true;
        this.text = response.message;

        this.loadingEstadoAprobacion = false;

        //await this.listarLicencias(idEmpresas);
        await this.listarSolicitudAccesos();
      } catch (e) {
        this.loadingEstadoAprobacion = false;
        console.log(e);
      }
    },
    preguntarEstadoLicencia(title = "", descripcion = "", estado = "") {
      // llamar al modal
      this.titleDialogLicencia = title;
      this.descripcionDialogLicencia = descripcion;
      this.estadoDialogLicencia = estado;

      this.dialogEstadoAprobacion = true;
    },
    clickRightOptions(e, item = {}) {
      e.preventDefault();
      this.menuFiltro = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menuFiltro = true;
      });
      this.item = item;

      console.log(this.item);
    },
    async listarSolicitudAccesos() {
      try {
        const idCliente = "1";
        const fechaInicio = this.parseDate(this.filter.fechainicio);
        const fechaFin = this.parseDate(this.filter.fechafin);
        const idestado = this.filter.idestado.id;
        this.loading = true;
        console.log(
          `solicitud-accesos?idcliente=${idCliente}&fechainicio=${fechaInicio}&fechafin=${fechaFin}&idestado=${idestado}`
        );
        const response = (
          await this.$xxhttp.request(
            `solicitud-accesos?idcliente=${idCliente}&fechainicio=${fechaInicio}&fechafin=${fechaFin}&idestado=${idestado}`
          )
        ).data;
        this.items = response.status ? response.data : [];

        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async exportarSolicitudAccesos() {
      try {

        const idClient = "1";
        const fechaInicio = this.parseDate(this.filter.fechainicio);
        const fechaFin = this.parseDate(this.filter.fechafin);
        const idestado = this.filter.idestado.id;
        console.log(this.$store.getters);
        let token = this.$store.getters.getToken
        let URL_BASE = process.env.VUE_APP_API_BASE_URL;

          let result = null
          this.loadingExport = true

          let url = `/exportar-solicitud-accesos?token=${token}&idcliente=${idClient}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idestado=${idestado}`
          result = await this.$xxhttp.request(url)
          console.log(result)

          if(result.data.error == 'token_expired' || result.data.error == 'token_invalid')
          {
            this.$store.dispatch('logout')
            window.location.href = '/login'
          }
          if(result.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            this.loadingExport = true
          }

          if(result.headers['content-type'] == 'application/json'){
            console.log('no hay datos')
            this.loadingExport = false
          }else{
            window.location.href= `${URL_BASE}${url}`;
            this.loadingExport = false
          }

      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>