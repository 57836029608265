<template>
  <v-form ref="form" @submit.prevent="validate">
  

    <v-text-field
      :label="'Usuario'"
      prepend-icon="mdi-account"
      v-model="form.username"
      required
      :error-messages="errorMessages.email"
    ></v-text-field>

    

    <v-text-field
      :label="'Contraseña'"
      prepend-icon="mdi-lock"
      type="password"
      v-model="form.password"
      required
    ></v-text-field>

    <!-- <v-checkbox
      v-model="form.remember"
      :label="$t('auth.remember')"
    ></v-checkbox> --> 

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        type="submit"
        x-small
        rounded

        @click.prevent="validate"
      >
        <v-icon>mdi-lock</v-icon>
        {{ 'INICIAR' }}
        </v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import axios from 'axios'
export default {
  data() {
    return {
      form: {
        username: null,
        password: null,
        remember: false,
      },
      errorMessages: {},
      loading: false,
    };
  },
  methods: {
    /*...mapActions({
      login: "login",
    }),*/
    async validate() {
      //if (this.$refs.form.validate()) {
        let body = {
          "usuario": this.form.username,
          "password": this.form.password,
          "path": "fg"
        }
        
        this.loading = true;
        //console.log(response, 'auth')
        try {
          
          const response = (await this.$xxhttp.request('login', 'post', body)).data
          //await this.login(body);
          //await this.$store.dispatch("login", body);
          await this.$store.dispatch("loginsave", response);
          //this.loading = false
        } catch (e) {
          console.log(e, 'errors')
          /*if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
          this.errorMessages = { email: [e.message] };
        } finally {
          this.loading = false;*/
        }
      },
    async listarLicencias(){
      try {
        
        const url = process.env.VUE_APP_API_BASE_URL;
        return axios.get(`${url}/licencias`)
          .then(response => {
            console.log(response, 'res')
          });
      } catch (e) {
        console.log(e)
      }
    }
    //},
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>

