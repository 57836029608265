const state = {
  barColor: 'rgba(0, 0, 0, .4), rgba(0, 0, 0, .8)',
  barImage: 'https://va-demo.okami101.io/admin/img/splash.79942c13.jpg',
  drawer: null,
};

const mutations = {
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
};

const actions = {

};

const getters = {};

const va = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default va;
